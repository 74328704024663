export const API_BASE = window._env_.API_BASE;
export const VERSION = window._env_.VERSION;
export const COMMIT_TIMESTAMP = window._env_.COMMIT_TIMESTAMP;
export const STRIPE_PK = window._env_.STRIPE_PK;
export const SENTRY_DSN = window._env_.SENTRY_DSN;
export const SENTRY_ENV = window._env_.SENTRY_ENV;
export const PENDO_API_KEY = window._env_.PENDO_API_KEY;

// Initialize Firebase
export const FIREBASE_CONFIG = {
  apiKey: window._env_.FIREBASE_API_KEY,
  authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
  databaseURL: window._env_.FIREBASE_DATABASE_URL,
  projectId: window._env_.FIREBASE_PROJECT_ID,
  storageBucket: window._env_.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window._env_.FIREBASE_MESSAGING_SENDER_ID,
  appId: window._env_.FIREBASE_APP_ID,
};
